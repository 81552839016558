import dynamic from "next/dynamic"

const AvatarCarouselBase = dynamic(() => import("./avatarCarousel"), {
  ssr: false,
})

export default function AvatarCarousel({ data }) {
  return data.length ? (
    <AvatarCarouselBase data={data} />
  ) : (
    <div className="w-full h-[480px] bg-black/90"></div>
  )
}
