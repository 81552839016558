"use client"
// import Image from "next/image";
import { useEffect, useState } from "react"
import { Container } from "@/src/components/common/container"
import { getExplore, getRecommendations, getTrending } from "@/src/apis/api"
import Wrapper from "@/src/components/common/Wrapper"
import { useQueryHook } from "@/src/hooks/query"
import { ExploreAvatarSkeleton } from "@/src/components/home/skeletons"
import AvatarCarousel from "./carousel"
import dynamic from "next/dynamic"
import AvatarCard from "@/src/components/shared/AvatarCard"
import Carousel from "react-multi-carousel"
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6"
import "react-multi-carousel/lib/styles.css"

const Slider = dynamic(() => import("@/src/components/slider/slider"), {
  ssr: false,
})

const slideShowData = [
  {
    title:
      "Connect like never before—two-way video chats with your favorite Avatars",
    color: "black",
  },
  {
    title:
      "Talk, laugh, and connect—two-way audio calls with immersive Avatars",
    color: "orange",
  },
  {
    title: "Engage in real-time video conversations with dynamic Avatars",
    color: "black",
  },
  {
    title:
      "Say hello to seamless live, interactive calls with engaging Avatars",
    color: "pink",
  },
  {
    title: "Enjoy face-to-face video chats with responsive, dynamic Avatars",
    color: "black",
  },
]

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 6,
    slidesToSlide: 6,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4,
    slidesToSlide: 4,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
    slidesToSlide: 2,
  },
}

// import RecommendationData from "@/src/components/shared/data/recommendations-data.json"
// import FeatureSlider from "@/src/components/shared/slider/featured-slider"
// import { useRouter } from "next/navigation";
// import { FaArrowRight, FaArrowLeft } from "react-icons/fa6";
// import { Swiper, SwiperSlide } from "swiper/react"
// import { Navigation } from "swiper/modules"

// const sliderData = [
//   {
//     id: 1,
//     src: "/assets/videos/vide2.mp4",
//     zIndex: 7,
//     left: 0,
//     name: "Lomin",
//     link: "#1",
//   },
//   {
//     id: 2,
//     src: "/assets/videos/vide2.mp4",
//     name: "Lomin",
//     link: "#1",
//   },
//   {
//     id: 3,
//     src: "/assets/videos/vide2.mp4",
//     name: "Lomin",
//     link: "#1",
//   },
//   {
//     id: 4,
//     src: "/assets/videos/vide2.mp4",
//     name: "Lomin",
//     link: "#1",
//   },
//   {
//     id: 5,
//     src: "/assets/videos/vide2.mp4",
//     name: "Lomin",
//     link: "#1",
//   },
//   {
//     id: 6,
//     src: "/assets/videos/vide2.mp4",
//     name: "Lomin",
//     link: "#1",
//   },
//   {
//     id: 7,
//     src: "/assets/videos/vide2.mp4",
//     name: "Lomin",
//     link: "#1",
//   },
//   {
//     id: 8,
//     src: "/assets/videos/vide2.mp4",
//     name: "Lomin",
//     link: "#1",
//   },
//   {
//     id: 9,
//     src: "/assets/videos/vide2.mp4",
//     name: "Lomin",
//     link: "#1",
//   },
//   {
//     id: 10,
//     src: "/assets/videos/vide2.mp4",
//     name: "Lomin",
//     link: "#1",
//   },
//   {
//     id: 11,
//     src: "/assets/videos/vide2.mp4",
//     name: "Lomin",
//     link: "#1",
//   },
//   {
//     id: 12,
//     src: "/assets/videos/vide2.mp4",
//     name: "Lomin",
//     link: "#1",
//   },
//   {
//     id: 13,
//     src: "/assets/videos/vide2.mp4",
//     name: "Lomin",
//     link: "#1",
//   },
//   {
//     id: 14,
//     src: "/assets/videos/vide2.mp4",
//     name: "Lomin",
//     link: "#1",
//   },
//   {
//     id: 15,
//     src: "/assets/videos/vide2.mp4",
//     name: "Lomin",
//     link: "#1",
//   },
// ];

export default function HomeSection() {
  // const swiperRef = useRef();
  // const router = useRouter();
  // const [featured, setFeatured] = useState([]);
  const [videoAvatars, setVideoAvatars] = useState([])
  const [companion, setCompanion] = useState([])
  const [indian, setIndian] = useState([])
  const [trending, setTrending] = useState([])

  const { data: recommendations } = useQueryHook(
    () => getRecommendations(),
    ["recommendations"]
  )

  console.log("recommendations,", recommendations)

  const { data: explore } = useQueryHook(() => getExplore(), ["explore"])
  const [activeButton, setActiveButton] = useState("All")
  const [search, setSearch] = useState("")
  // const countryUrls = {
  //   All: "",
  //   indian: "india",
  // };

  // const buttons = [
  //   { name: "All", icon: "/assets/svg/trend.svg" },
  //   // { name: "AI Girlfriend", icon: null, emoji: "💕" },
  //   {
  //     name: "Indian",
  //     icon: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTLvMkoPFEBjp-8KsyNNgIQJNhylWJuxr0Ayg&s",
  //     emoji: "",
  //   },
  // ];

  const filteredExplore = explore?.data?.data
    .filter((data) => {
      // Filter based on the active button
      if (activeButton === "All") {
        return true // Show all data
      } else if (activeButton === "AI Girlfriend") {
        return data.category === "AI Girlfriend"
      }
      return false
    })
    .filter(
      (data) =>
        data?.name?.toLowerCase().includes(search.toLowerCase()) ||
        data?.username?.toLowerCase().includes(search.toLowerCase()) ||
        data?.profession?.toLowerCase().includes(search.toLowerCase())
    )

  const listView = filteredExplore?.slice(0, 10)
  const slideView = filteredExplore?.slice(0, 10)

  if (recommendations?.data?.data.length) {
    const featured = recommendations?.data?.data
    const videoSlides = [0, 2, 4]
    const audioSlides = [1, 3]
    for (const avatar of featured) {
      const hasVideoFeature = avatar.replica_id && avatar.persona_id
      if (hasVideoFeature && videoSlides.length) {
        slideShowData[videoSlides.at(0)] = {
          ...slideShowData.at(videoSlides.at(0)),
          image: avatar?.medium_photo,
          name: avatar?.name,
          username: avatar?.username,
          video: true,
        }
        videoSlides.shift()
      } else {
        slideShowData[audioSlides.at(0)] = {
          ...slideShowData.at(audioSlides.at(0)),
          image: avatar?.medium_photo,
          name: avatar?.name,
          username: avatar?.username,
        }
        audioSlides.shift()
      }
    }
  }

  useEffect(() => {
    getExplore({ call_type: "video" })
      .then((data) => {
        let videoAvatars = data?.data?.data
        if (videoAvatars.length < 6)
          videoAvatars = [...videoAvatars, ...videoAvatars]
        setVideoAvatars(videoAvatars)
      })
      .catch(() => console.log("failed to fetch video avatars"))

    getExplore({ "AI+Companion": "video" })
      .then((data) => {
        setCompanion(data?.data?.data)
      })
      .catch(() => console.log("failed to fetch video avatars"))

    getExplore({ country: "india" })
      .then((data) => {
        setIndian(data?.data?.data)
      })
      .catch(() => console.log("failed to fetch Indian avatars"))

    getTrending({ limit: 10, hours: 365 })
      .then((data) => {
        setTrending(data?.data?.data)
      })
      .catch(() => console.log("fetch trending failed"))
  }, [])

  // const handleFilterClick = (name) => {
  //   if ((activeButton == "All") & (name != "Indian")) return
  //   const selectedFilter = name
  //   const urlPath = countryUrls[selectedFilter.toLowerCase()] || ""

  //   setActiveButton(selectedFilter)

  //   router.push(`/explore`, { shallow: true })
  // }

  return (
    <Wrapper>
      <Container>
        {recommendations?.data?.data.length ? (
          <Slider data={slideShowData} />
        ) : (
          <div className="bg-gray-200 md:h-[500px] h-[350px] w-full rounded-2xl" />
        )}
      </Container>
      {explore?.data?.data?.length ? (
        <div className="p-4">
          <div>
            <h3 className="text-indigo-950 dark:text-white flex items-center gap-2 text-xl font-semibold xs:mb-0">
              Video Chat
            </h3>
            <Carousel
              swipeable={true}
              draggable={false}
              responsive={responsive}
              ssr={true}
              infinite={true}
              keyBoardControl={true}
              containerClass="carousel-container "
              className="w-full h-72 my-3"
            >
              {videoAvatars.map((data, index) => {
                if (!data.medium_photo) return null
                return (
                  <div
                    key={index}
                    className="h-72 overflow-hidden border-transparent border-[4px] "
                  >
                    <AvatarCard {...data} />
                  </div>
                )
              })}
            </Carousel>
            <h3 className="text-indigo-950 dark:text-white flex items-center gap-2 text-xl  font-semibold xs:mb-0">
              Trending
            </h3>
            <Carousel
              swipeable={true}
              draggable={false}
              responsive={responsive}
              ssr={true}
              infinite={true}
              containerClass="carousel-container"
              className="w-full h-72 my-3"
            >
              {trending.map((data, index) => {
                if (!data.medium_photo) return null
                return (
                  <div
                    key={index}
                    className="h-72 overflow-hidden border-transparent border-[4px]"
                  >
                    <AvatarCard {...data} />
                  </div>
                )
              })}
            </Carousel>
          </div>
          <AvatarCarousel data={slideView} />
          <div className="pt-4">
            <h3 className="text-indigo-950 dark:text-white flex items-center gap-2 text-xl font-semibold xs:mb-0">
              AI Companion
            </h3>
            <Carousel
              swipeable={true}
              draggable={false}
              responsive={responsive}
              ssr={true}
              infinite={true}
              keyBoardControl={true}
              containerClass="carousel-container "
              className="w-full h-72 my-3"
            >
              {companion.map((data, index) => {
                if (!data.medium_photo) return null
                return (
                  <div
                    key={index}
                    className="h-72 overflow-hidden border-transparent border-[4px] "
                  >
                    <AvatarCard {...data} />
                  </div>
                )
              })}
            </Carousel>
            <h3 className="text-indigo-950 dark:text-white flex items-center gap-2 text-xl  font-semibold xs:mb-0">
              Indian
            </h3>
            <Carousel
              swipeable={true}
              draggable={false}
              responsive={responsive}
              ssr={true}
              infinite={true}
              containerClass="carousel-container"
              className="w-full h-72 my-3"
            >
              {indian.map((data, index) => {
                if (!data.medium_photo) return null
                return (
                  <div
                    key={index}
                    className="h-72 overflow-hidden border-transparent border-[4px]"
                  >
                    <AvatarCard {...data} />
                  </div>
                )
              })}
            </Carousel>
          </div>
        </div>
      ) : (
        <div className="px-4">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 my-10">
            {Array.from({ length: 10 }).map((_, index) => (
              <ExploreAvatarSkeleton key={index} />
            ))}
          </div>
        </div>
      )}
    </Wrapper>
  )
}
