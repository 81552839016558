import React from "react"

const Container = ({ children, className }) => {
  return (
    <>
      <div className={`mt-0 md:mt-6 lg:mt-6 xl:mt-4 2xl:mt-8 px-4 ${className}`}>{children}</div>
    </>
  )
}

const SliderContainer = ({ children }) => {
  return (
    <>
      <div className="2xl:pl-3 xl:pl-14 md:px-8 lg:px-10 md:pr-11 lg:pr-10 mx-auto 2xl:px-0">{children}</div>
    </>
  )
}

export { Container, SliderContainer }
