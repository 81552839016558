export function FeaturedAvatarSkeleton() {
  return (
    <div className="flex flex-col items-center relative">
      <div className="relative w-[7.2rem] aspect-square lg:w-32">
        {/* Gradient border */}
        <div className="absolute inset-0 rounded-full p-1 bg-white">
          {/* Inner circle (avatar placeholder) */}
          <div className="w-full h-full rounded-full bg-white "></div>
        </div>
        {/* Chat icon placeholder */}
        <div className="absolute top-1 right-1 w-8 h-8 rounded-full bg-white"></div>
      </div>
      {/* Label placeholder */}
      <div className="w-32 h-6 backdrop-blur py-[2px] bg-white rounded-full absolute -bottom-1"></div>
    </div>
  );
}

export function ExploreAvatarSkeleton() {
  return (
    <div className="w-full h-[400px] relative rounded-md overflow-hidden bg-white">
      <div className="absolute left-3 bottom-5 w-[180px]">
        <div className="w-full px-3 py-3 bg-neutral-600/40 rounded-full backdrop-blur-sm">
          <div className="h-4 w-3/4 bg-gray-300 rounded mb-2" />
          <div className="h-3 w-1/2 bg-gray-300 rounded" />
        </div>
      </div>
    </div>
  );
}
