import Image from "next/image";
import Link from "next/link";
import { Play, Video, VolumeX, Volume2 } from "lucide-react";
import { useRouter } from "next/navigation";
import { useRef, useState } from "react";
import { useMute } from "@/src/context/MuteContext";

const AvatarCard = ({
  username,
  medium_photo,
  name,
  profession,
  replica_id,
  persona_id,
  video,
  voice_language
}) => {
  const router = useRouter();
  const hasVideoCall = !!replica_id && !!persona_id;

  const videoRef = useRef(null);
  // const [isMuted, setIsMuted] = useState(true); // State to track mute/unmute
  const { isMuted, toggleMute } = useMute();

  const handleMuteToggle = (e) => {
    e.stopPropagation();
    e.preventDefault();
    toggleMute();
    // setIsMuted((prev) => !prev);
    if (videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted;
    }
  };

  return (
    <Link
      href={`/chat/${username}`}
      className="max-w-96 group"
      onMouseEnter={() => videoRef.current?.play()}
      onMouseLeave={() => videoRef.current?.pause()}
    >
      <div className="w-full h-full relative rounded-md overflow-hidden shadow">
        {hasVideoCall && (
          <>
            <span className="absolute size-10 flex items-center justify-center group-hover:opacity-0 group-hover:-z-10 transition-all duration-300 top-3 left-3 z-10 bg-neutral-600/40 rounded-full text-white backdrop-blur border">
              <Play size={20} fill="white" />
            </span>
            <button
              onClick={handleMuteToggle}
              className="absolute size-10 flex items-center justify-center group-hover:opacity-100 group-hover:z-10 transition-all duration-300 top-3 left-3 bg-neutral-600/40 rounded-full text-white backdrop-blur border"
            >
              {isMuted ? (
                <VolumeX size={20} fill="white" />
              ) : (
                <Volume2 size={20} fill="white" />
              )}
            </button>
          </>
        )}
        <div className="w-full relative overflow-hidden h-full">
          {hasVideoCall && (
            <video
              src={video}
              // autoPlay
              controls={false}
              muted={isMuted} // Set based on the muted state
              loop
              className="absolute w-full opacity-0 group-hover:opacity-100 transition-all duration-500"
              ref={videoRef}
            />
          )}

          <Image
            src={medium_photo}
            alt={name}
            width={370}
            height={200}
            className="w-full h-full object-cover"
          />
        </div>

        <div className="w-[180px] h-[45.34px] left-[11px] bottom-5 absolute">
          <div className="left-[3.37px] top-0 absolute flex-col justify-center items-start gap-3 inline-flex">
            <div className="w-full px-3 py-3 bg-neutral-600/40 rounded-full backdrop-blur-sm flex-col justify-center items-center gap-3 flex">
              <div className="justify-start items-center">
                <div className="text-white text-sm mr-5 font-medium text-wrap-nowrap">
                  {name}
                </div>
                <div className="text-white text-xs mr-5 font-medium text-wrap-nowrap">
                  {profession} {voice_language ? `(${voice_language})` : ""}
                </div>
              </div>
            </div>
          </div>
        </div>

        {hasVideoCall && (
          <button
            className="size-12 -z-50 group-hover:z-10 opacity-0 group-hover:opacity-100 transition-all duration-300 absolute bottom-3 cursor-pointer flex justify-center items-center right-3 bg-white/15 rounded-full transform translate-y-10 group-hover:translate-y-0"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              router.push(`/chat/${username}?video=true`);
            }}
          >
            <div className="size-10 bg-gradient-to-b from-neutral-400 to-pink-500 rounded-full gradient__box justify-center items-center inline-flex">
              <Video size={29} stroke="white" fill="white" />
            </div>
          </button>
        )}

        <div className="size-12 absolute top-3 cursor-pointer flex justify-center items-center right-3 bg-white/15 rounded-full">
          <Link
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
            href={`/chat/${username}`}
          >
            <div className="size-10 bg-gradient-to-b from-neutral-400 to-pink-500 rounded-full gradient__box justify-center items-center inline-flex">
              <Image
                src={`/assets/svg/chat.svg`}
                className="rounded-full"
                alt="chat-icon"
                width={29}
                height={29}
                style={{ width: "25px!important", height: "35px" }}
              />
            </div>
          </Link>
        </div>
      </div>
    </Link>
  );
};

export default AvatarCard;
